import Axios from 'axios'
import { Toast,Notify } from 'vant'
 
// 创建实例
const service = Axios.create({
  // baseURL: 'http://192.168.1.234:9095/seller-admin', // 线下接口基础地址
  baseURL: 'https://oa.qilixiang.com/seller-admin', // 线上接口基础地址
  timeout: 50000, // 响应超时时间
})
 
// 请求拦截器
 service.interceptors.request.use(
   (config) => {
    Toast.loading({
			forbidClick: true,
		});
     // 挂在token, 根据自己方式判断和自定义参数
     if(localStorage.getItem('token')){
       config.headers['Authorization'] = ''
     }
     return config
   },
   (error) => {
     return Promise.reject(error)
   }
 )
 // 响应拦截器
 service.interceptors.response.use(
   (res) => {
    Toast.clear();
    if (res.data.code === 200) {
			return res
		} else {
			Notify({
				message:res.data.msg || "Error",
				type: "danger",
				duration: 5 * 1000
			});
			return Promise.reject(res.data.msg)
		}
   },
   (error) => {
    Notify({
			message: '服务器繁忙,请稍后再试...',
			type: "warning",
			duration: 5 * 1000
		});
		return Promise.reject(error);
   }
 )
 
 export default service
 
 
 