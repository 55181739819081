<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/ic_no_data.png">
    <div class="text">暂无内容</div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
<style lang="scss" scoped>
.home{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  img{
    display: block;
    margin: 0 auto;
  }
}
.text{
  margin-top: 8px;
  color: #555555;
}
</style>
