import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomeView
  },
  {
    path: '/home',
    name: 'indexs',
    component: function () {
      return import('@/views/home/indexs.vue')
    },
    meta:{title:'绩效考评',showMenu:true}
  },
  {
    path: '/privacyAgreement',
    name: 'privacyAgreement',
    component: function () {
      return import('@/views/home/privacyAgreement.vue')
    },
    meta:{title:'隐私政策'}
  },
  {
    path: '/termsToService',
    name: 'termsToService',
    component: function () {
      return import('@/views/home/termsToService.vue')
    },
    meta:{title:'用户协议'}
  },
  {
    path: '/gys/privacyAgreement',
    name: '/gysprivacyAgreement',
    component: function () {
      return import('@/views/home/privacyAgreement.vue')
    },
    meta:{title:'隐私政策'}
  },
  {
    path: '/gys/termsToService',
    name: '/gystermsToService',
    component: function () {
      return import('@/views/home/termsToService.vue')
    },
    meta:{title:'用户协议'}
  },
  {
    path: '/register',
    name: 'register',
    component: function () {
      return import('@/views/home/register.vue')
    },
    meta:{title:'用户注册'}
  },
  {
    path: '/quote/:q/:s?',
    name: 'quote',
    component: function () {
      return import('@/views/home/quote.vue')
    },
    meta:{title:'采购报价'}
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('@/views/home/login.vue')
    },
    meta:{title:'登录'}
  },
  {
    path: '/portal',
    name: 'portal',
    component: function () {
      return import('@/views/home/portal.vue')
    },
    meta:{title:'首页'}
  },
  {
    path: '/mine',
    name: 'mine',
    component: function () {
      return import('@/views/home/mine.vue')
    },
    meta:{title:'我的'}
  },
]
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path == '/'){
    next();		
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});


export default router
